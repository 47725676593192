import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import {
  userDecisionsKeys,
  useUserDecisionsQueryCache,
} from "src/data/userDecisions/userDecisionsQueryCache"
import {
  TDecisionsFilter,
  TUserDecisionsPostRequest,
  TUserDecisionsPostResponse,
  TUserDecisionsResponse,
} from "src/data/userDecisions/userDecisionsTypes"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export function useFetchUserDecisions({
  userId,
  filters,
  options,
}: {
  userId: string
  filters?: TDecisionsFilter
  options?: UseQueryOptions<
    TUserDecisionsResponse,
    AxiosError,
    TUserDecisionsResponse,
    ReturnType<typeof userDecisionsKeys.decisions>
  >
}) {
  async function fetchUserDecisions(
    userId: string,
    filters?: TDecisionsFilter
  ) {
    const result = await minutApiHttpClient.get<TUserDecisionsResponse>(
      `${API_DEFAULT}/users/${userId}/decisions`,
      {
        params: filters,
      }
    )
    return result.data
  }

  return useQuery({
    queryKey: userDecisionsKeys.decisions({
      userId,
      filters,
    }),
    queryFn: () => fetchUserDecisions(userId, filters),
    ...options,
  })
}

export function usePostUserDecisions({ userId }: { userId: string }) {
  const { addCachedDecision } = useUserDecisionsQueryCache()
  async function postUserDecisions(body: TUserDecisionsPostRequest) {
    const result = await minutApiHttpClient.post<
      TUserDecisionsPostRequest,
      AxiosResponse<TUserDecisionsPostResponse>
    >(`${API_DEFAULT}/users/${userId}/decisions/`, body)

    addCachedDecision({ userId, decision: result.data })

    return result.data
  }

  return useMutation<
    TUserDecisionsPostResponse,
    AxiosError,
    TUserDecisionsPostRequest
  >({ mutationFn: postUserDecisions })
}

export function useDeleteUserDecision({ userId }: { userId: string }) {
  const queryClient = useQueryClient()

  async function deleteUserDecisions(decisionId: string) {
    const result = await minutApiHttpClient.delete(
      `${API_DEFAULT}/users/${userId}/decisions/${decisionId}`
    )

    queryClient.invalidateQueries(userDecisionsKeys.decisions({ userId }))

    return result.data
  }

  return useMutation({ mutationFn: deleteUserDecisions })
}
